import React from "react";
import {Helmet} from "react-helmet";

class codepen extends React.Component {
  render () {
    return (
      <Helmet>
          <script async src="https://static.codepen.io/assets/embed/ei.js"></script>
      </Helmet>
    );
  }
};
export default codepen
