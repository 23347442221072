import React from 'react'
import { Link } from 'gatsby'
import Codepen from '../../components/embedCodepen'
import Layout from '../../components/layout'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';




const Portfolio1 = ({data}) => (
  <Layout>
      <Codepen/>
      <main className="page--demo demo--nutrition">        
      <Helmet>
        <title>{data.site.siteMetadata.demoNutritionTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
        <section className="slab slab--main">
          <Header siteTitle="Rob DiMarzo" /> 

            <div className="slab__inner">
                <div className="pageblock__content">   
                  <div className="pageblock__title">
                      <h1>Nutritional Facts Transformation</h1>
                  </div>  
                  <p className="text--bold">A demo of how CSS can modify the layout and style of a single HTML template.</p> 
                  <p>
                  US Nutrition Facts come in a variety of aspect ratios to fit different container sizes. I noticed most of the actual content is consistent across the variations (+- a few labels). The big difference is the layout. CSS to the rescue?
                  </p>

                  <p>
                  With that in mind, I've written one source of semantic HTML (a whole lot of DLs seemed appropriate) that gets presented at different sizes via CSS. Flexbox is doing the heavy lifting, but I am also using CSS Grid for the main outer structure. For the small label, I am using "display: contents" to allow all the content to flow freely of their containers. Due to this, it may not display correctly in older browsers.
                  </p>

                  <p>
                  Source: 
                  </p>
                  <a href=" https://www.fda.gov/downloads/Food/GuidanceRegulation/GuidanceDocumentsRegulatoryInformation/LabelingNutrition/UCM511964.pdf" target="_blank">
                  fda.gov - The New Nutrition Facts Labels: Examples of Different Label Formats
                  </a>  
                </div>
                <div className="pageblock__demo">
                  <p className="codepen" data-height="700" data-theme-id="36208" data-default-tab="result" data-user="robdimarzo" data-slug-hash="MQdoOZ"  data-pen-title="Transforming Nutrition Facts Label Formats">
                    <span>See the Pen <a href="https://codepen.io/robdimarzo/pen/MQdoOZ/">
                    Transforming Nutrition Facts Label Formats</a> by Rob DiMarzo (<a href="https://codepen.io/robdimarzo">@robdimarzo</a>)
                    on <a href="https://codepen.io">CodePen</a>.</span>
                  </p>
                </div>
            </div>
        </section>
    </main>
  </Layout>
)
export const query = graphql`
  query NutritionQuery {
    site {
      siteMetadata {
        title
        demoNutritionTitle
      }
    }
  }
`
export default Portfolio1
